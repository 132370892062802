<script>
import lottie from 'lottie-web';
import animationData from '@/assets/animations/home1.json';
import animationDataMobile from '@/assets/animations/homemobile.json';

export default {
  name: 'faq',

  mounted() {
    const params = {
      container: this.$refs.bg,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    };

    lottie.loadAnimation(params);

    params.container = this.$refs.bgmobile;
    params.animationData = animationDataMobile;

    lottie.loadAnimation(params);
  },
};

</script>

<template>
  <div class="faq wrap">
    <the-nav>
      <router-link active-class="is-active" :to="{ name: 'home' }">
        Hire now
      </router-link>
    </the-nav>

    <header class="faq_header">

      <h1 class="title is-2">employers</h1>
      <h2>Got a question about the Heroes Jobs app?<br>We got you.</h2>
      <img class="faq_conclusion_img" src="@/assets/img/emojis/hero.png" alt="">

      <div class="bg" ref="bg"></div>
      <div class="bgmobile" ref="bgmobile"></div>
    </header>

    <body>

      <div class="faq_synopsis">
        <ul>
          <li class="faq_synopsis_intralink">
            <a href="#q1">What is a hiring event?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q2">How many hiring events can I have in one week?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q3">Can I cancel a hiring event?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q4">Can I reject an application?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q5">Do I need to contact the applicants?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q6">Can I see the applicants in my system?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q7">What if I have too few applicants?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q8">Why is video the main focus of Heroes Jobs?</a>
          </li>
          <li class="faq_synopsis_intralink">
            <a href="#q9">How does Heroes Jobs facilitate diversity and inclusion in hiring?</a>
          </li>
        </ul>
      </div>

      <div class="faq_questions">
        <ul>
          <li class="faq_questions_item" id="q1">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/calendar.png" alt="">
              What is a hiring event?
            </h3>
            <p>A hiring event is an event that you host, dedicated to interviewing qualified applicants for a position--or multiple positions--that you’re looking to fill.</p>
            <p>With Heroes Jobs, you:</p>
            <ol class="faq_questions_item_sublist">
              <li>Choose a day and period of time to conduct multiple interviews (for example, next Tuesday between 1:00 p.m. and 5:00 p.m.).</li>
              <li>Specify the duration of each interview (15 minutes, 30 minutes, 1 hour, etc.).</li>
              <li>Choose to host video interviews or on-site interviews.</li>
            </ol>
            <p>Then:</p>
            <ol class="faq_questions_item_sublist">
              <li>Your pre-screened applicants will book interviews in your scheduled hiring event.</li>
              <li>On the day of the hiring event, you'll interview all scheduled applicants.</li>
              <li>Afterwards, you can accept or reject applicants in one tap on the app. We take care of the rest!</li>
            </ol>
          </li>

          <li class="faq_questions_item" id="q2">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/thunderbolt.png" alt="">
              How many hiring events can I have in one week?
            </h3>
            <p>You can have as many hiring events as you want! We always recommend having 3 hiring events in one week. This gives more opportunities to your applicants to schedule interviews with you.</p>
          </li>

          <li class="faq_questions_item" id="q3">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/forbidden.png" alt="">
              Can I cancel a hiring event?
            </h3>
            <p>Yes, you can always cancel a hiring event.</p>
          </li>

          <li class="faq_questions_item" id="q4">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/reject.png" alt="">
              Can I reject an application?
            </h3>
            <p>Yes, you can reject an application.</p>
          </li>

          <li class="faq_questions_item" id="q5">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/old_phone.png" alt="">
              Do I need to contact the applicants?
            </h3>
            <p>No, we do all of that for you. We screen, schedule, and engage with your applicants.</p>
            <p>Before your hiring event, you only need to check attendance to see who is coming.</p>
            <p>Afterwards, you just have to note the outcome of the interviews in the app (accepted, rejected, or no-show). That information is communicated to your internal HR system.</p>
          </li>

          <li class="faq_questions_item" id="q6">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/eyes.png" alt="">
              Can I see the applicants in my system?
            </h3>
            <p>Yes, all the applications are connected to your system.</p>
          </li>

          <li class="faq_questions_item" id="q7">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/magnifyingglass.png" alt="">
              What if I have too few applicants?
            </h3>
            <p>Heroes Jobs is not an applicant provider. All the applicants you see come from your company’s system. If you feel the volume is low, get in touch with your HR representative to add more applicants.</p>
          </li>

          <li class="faq_questions_item" id="q8">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/camera.png" alt="">
              Why is video the main focus of Heroes Jobs?
            </h3>
            <p>Videos have numerous proven benefits that improve the application review process, saving time and allowing hiring managers to complete pre-screening at a faster rate.</p>
            <p>For one, viewers retain <a href="https://www.insivia.com/50-must-know-stats-about-video-animation-marketing-2013/" target="_blank">95% of a message when they watch it on video</a>, compared to 10% when reading it in a text.</p>
            <p>We can also get a better impression of a person’s personality by seeing and hearing them via video rather than reading their job application or resume.</p>
            <p>If you’d like to learn about more benefits of using video as a recruitment and interview method, check out our <a href="https://blog.heroes.jobs/">blog</a> or <a href="mailto:hello@heroes.jobs">contact us</a>.</p>
          </li>

          <li class="faq_questions_item" id="q8">
            <h3 class="faq_questions_item_title">
              <img class="faq_questions_item_title_img" src="@/assets/img/emojis/100.png" alt="">
              How does Heroes Jobs facilitate diversity and inclusion in hiring?
            </h3>
            <p>Heroes puts personality and individuality front and center. Videos give applicants a chance to truly present themselves, allowing them to stand out beyond the limited info on an application or resume.</p>
            <p>Our goal is to minimize arbitrary barriers to entry and facilitate diversity and inclusion in companies’ hiring processes.</p>
          </li>

        </ul>
      </div>

      <div class="faq_conclusion">
        <img class="faq_conclusion_img" src="@/assets/img/emojis/raised_hand.png" alt="">
        <p>Do you have another question?</p>
        <p>Get in touch with your Heroes Jobs representative or <a href="mailto:hello@heroes.jobs">email us</a>.</p>
      </div>

    </body>
  </div>
</template>


<style lang="scss" scoped>
.faq {

  &_conclusion {
    margin-top: 70px;
    text-align: center;

    &_img {
      height: 30px;
      width: 30px;
    }
  }

  &_synopsis {
    &_intralink {
      margin-bottom: 5px;
    }
  }

  &_header {
    text-align: center;
    margin-top: 90px;
    margin-bottom: 90px;
  };

  h2 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 24px;
  };

  &_questions {

    margin-top: 50px;

    &_item {

      &_title {
        font-weight: 700;
        font-size: 22px;
        padding-top: 30px;
        padding-bottom: 20px;

        &_img {
          height: 26px;
          width: 26px;
          margin: 0 10px -5px 0;
        };
      };

      &_sublist {
        margin: 0 40px 20px 40px;
        list-style-type: circle;
      };
    };
  };

  p{
    margin-bottom: 10px;
  };
};

</style>
